.main-page {
  position: relative;
  .welcome__text {
    position: absolute;
    font-size: 45px;
    text-transform: uppercase;
    margin-top: 150px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 1250px) {
      font-size: 40px;
    }
    @media screen and (max-width: 1000px) {
      font-size: 30px;
    }
    @media screen and (max-width: 700px) {
      font-size: 25px;
    }
  }
}
.verify__text {
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;
}
