.profile__container {
  width: 100%;
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px 25px;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  .change__info-row {
    display: flex;
    align-items: center;
    gap: 45px;
    &__item {
      width: 33%;
      padding: 5px 10px;
    }
  }
}

.tezasd {
  width: 300px;
  margin-left: auto;
}

.userInformation__container {
  .title {
    text-align: left;
  }
}
