.addrace-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-top: 30 px;
  .title {
    margin-bottom: 30px;
  }
  h3 {
    margin-bottom: 25px;
  }

  .main-information {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
    .race-name__block {
      width: 100%;
    }
    .first-row {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;

      .route-input {
        width: 35%;
      }
    }
  }
}

.choose-type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;
}
.strava-type {
  width: 50%;
}

.activity-type {
  display: flex;
  align-items: center;
  gap: 10px;
}

.strava-type {
  text-align: left;
  .segments-with-strava {
    width: 80%;
  }
  .strava-input {
    width: 70%;
    margin-right: 10px;
  }
}

.addrace-footer {
  text-align: right;
  .cancel-btn {
    margin-left: 20px;
  }
}

.custom__label {
  padding: 0;
  margin: 0;
  font-weight: bold;
  text-align: left;
  padding-left: 5px;
}
