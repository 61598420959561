.dashboard-container {
  display: flex;
  margin-top: 90px;
  justify-content: space-between;
  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
}

.sidebar-left {
  width: 15%;
  @media screen and (max-width: 820px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 15px;
  }
}

.sidebar-right {
  width: 30%;
  padding: 0 20px;
}
.recent-races__container {
  flex-grow: 1;
  padding: 0 20px;
  margin-top: 0px !important;
}

.top-sidebar {
  width: 100%;
}

.search-cups__section {
  display: flex;
  gap: 15px;
  margin-bottom: 40px;
}
