@import '../../../styles/colors.scss';
.navigation {
  width: 100%;
  height: 60px;
  display: flex;
  padding: 5px 70px;
  justify-content: space-between;
  align-items: center;
  -webkit-box-shadow: 4px 8px 18px -5px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 4px 8px 18px -5px rgba(34, 60, 80, 0.2);
  box-shadow: 4px 8px 18px -5px rgba(66, 71, 74, 0.2);
  background-color: $white;
  position: fixed;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 390px) {
    padding: 5px 55px;
  }
}

.logo__btn {
  position: absolute;
  left: 8%;
  top: 8px;
}

.logo__box {
  width: 90%;
}

.nav-bar {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;

  h4 {
    margin-right: 50px;
  }
}

.logo {
  svg {
    cursor: pointer;
  }
}

.login_button {
  text-decoration: none;
  color: #000;
  font-size: 21px;
  font-weight: bold;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: blue;
  }
}
.nav__items {
  display: flex;
  list-style: none;
  align-items: center;
  gap: 15px;
}
.nav__item {
  font-weight: 500;
  font-size: 1.1rem;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}

.link-items {
  color: black;
  font-weight: bold;
  text-transform: uppercase;
}

.changelang-item {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.regular-pos {
  align-items: flex-end;
}

.drop-pos {
  align-items: center;
}
.link-btn {
  border: none;
  text-decoration: none;
  text-transform: uppercase;
  background: none;
  cursor: pointer;
  color: $blue;
}

.userContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  &:first-child {
    margin-top: 10px;
  }
}

.nav-items {
  text-transform: uppercase;
}

#menu__toggle {
  opacity: 0;
}
#menu__toggle:checked + .menu__btn > span {
  transform: rotate(45deg);
}
#menu__toggle:checked + .menu__btn > span::before {
  top: 0;
  transform: rotate(0deg);
}
#menu__toggle:checked + .menu__btn > span::after {
  top: 0;
  transform: rotate(90deg);
}
#menu__toggle:checked ~ .menu__box {
  left: 0 !important;
}
.menu__btn {
  position: fixed;
  top: 12px;
  left: 45px;
  width: 30px;
  height: 30px;
  z-index: 10;
  cursor: pointer;
}
.menu__btn > span,
.menu__btn > span::before,
.menu__btn > span::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #616161;
  transition-duration: 0.25s;
}
.menu__btn > span::before {
  content: '';
  top: -8px;
}
.menu__btn > span::after {
  content: '';
  top: 8px;
}
.menu__box {
  display: block;
  position: fixed;
  top: 60px;
  left: -100%;
  width: 300px;
  height: 100%;
  margin: 0;
  padding: 40px 0;
  list-style: none;
  background-color: #fff;
  border-top: 1px solid #f1f1f9;
  box-shadow: 0px 1px 1px rgba(25, 29, 58, 0.01), 0px 2px 1px -1px rgba(25, 29, 58, 0.01),
    0px 1px 3px rgba(25, 29, 58, 0.02);
  transition-duration: 0.5s;
  z-index: 9;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
  li {
    margin-bottom: 35px;
    font-size: 16px;
    width: 100%;
    padding-left: 45px;
    text-align: left;
  }
}
.menu__item {
  display: block;
  padding: 12px 24px;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
  transition-duration: 0.25s;
}
.menu__item:hover {
  background-color: #cfd8dc;
}

/* Mixin */
/* reset */

// nav {
//   background-color: $white;
//   height: 50px;
//   padding: 0 16px;
//   display: -ms-flexbox;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: flex;
//   -webkit-align-items: center;
//   align-items: center;

//   /* fade in checked menu */
// }
// nav .m-menu__checkbox {
//   display: none;
// }
// nav label.m-menu__toggle {
//   cursor: pointer;
// }
// nav .m-menu {
//   position: absolute;
//   top: 0;
//   left: 0;
//   max-width: 450px;
//   width: calc(100vw - 30px);
//   height: 100%;
//   -moz-transform: translate3d(-450px, 0, 0);
//   -o-transform: translate3d(-450px, 0, 0);
//   -ms-transform: translate3d(-450px, 0, 0);
//   -webkit-transform: translate3d(-450px, 0, 0);
//   transform: translate3d(-450px, 0, 0);
//   -moz-transition: transform 0.35s;
//   -o-transition: transform 0.35s;
//   -webkit-transition: transform 0.35s;
//   transition: transform 0.35s;
//   z-index: 1;
//   overflow: hidden;
//   background-color: #fff;
// }
// nav .m-menu__overlay {
//   background-color: rgba(103, 103, 103, 0.5);
//   position: absolute;
//   top: 0;
//   width: 100%;
//   bottom: 0;
//   z-index: 1;
//   display: none;
//   height: 100%;
//   min-height: 100vh;
// }
// nav .m-menu__header {
//   padding: 0 16px;
//   height: 50px;
//   display: -ms-flexbox;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: flex;
//   // -webkit-justify-content: space-around;
//   // justify-content: space-around;
//   // -ms-flex-pack: space-around;
//   -webkit-align-items: center;
//   align-items: center;
//   border-bottom: 1px solid #e8e8e8;
// }
// nav .m-menu__header span {
//   font-size: 1.2rem;
//   font-weight: bold;
//   text-align: center;
//   width: 100%;
// }
// nav .m-menu .m-menu {
//   -moz-transform: translate3d(480px, 0, 0);
//   -o-transform: translate3d(480px, 0, 0);
//   -ms-transform: translate3d(480px, 0, 0);
//   -webkit-transform: translate3d(480px, 0, 0);
//   transform: translate3d(480px, 0, 0);
// }
// nav .m-menu ul {
//   height: 100%;
//   overflow-y: auto;
// }
// nav .m-menu ul li a,
// nav .m-menu ul li label {
//   display: block;
//   text-align: left;
//   padding: 0 15px;
//   line-height: 47px;
//   text-decoration: none;
//   color: #333;
//   cursor: pointer;
//   font-size: 21px;
//   font-weight: bold;
//   border-bottom: 1px solid #e8e8e8;
//   position: relative;
// }
// nav .m-menu ul li label.a-label__chevron::after {
//   content: '';
//   position: absolute;
//   display: inline-block;
//   height: 10px;
//   width: 10px;
//   border-color: #333;
//   border-style: solid;
//   border-width: 1px 1px 0 0;
//   -moz-transform: rotate(45deg);
//   -o-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   -webkit-transform: rotate(45deg);
//   transform: rotate(45deg);
//   top: 50%;
//   margin-top: -5px;
//   right: 16px;
// }
// nav .m-menu ul li .-invisible {
//   border-bottom: 0;
// }
// nav .m-menu .m-menu label.m-menu__toggle {
//   display: -ms-flexbox;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: flex;
//   border-bottom: 0;
//   padding: 0;
//   -webkit-box-sizing: content-box;
//   -moz-box-sizing: content-box;
//   box-sizing: content-box;
// }
// nav .m-menu__checkbox:checked ~ .m-menu__overlay {
//   display: block;
// }
// nav .m-menu__checkbox:checked ~ .m-menu {
//   -moz-transform: translate3d(0, 0, 0);
//   -o-transform: translate3d(0, 0, 0);
//   -ms-transform: translate3d(0, 0, 0);
//   -webkit-transform: translate3d(0, 0, 0);
//   transform: translate3d(0, 0, 0);
// }

// .wrapper {
//   width: 480px;
//   height: 667px;
//   margin: 0 auto;
//   overflow: hidden;
//   background-color: #fff;
//   position: relative;
// }

.show-popover {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.burger__logo {
  margin: 7px 0 15px 0px;
}

.lala {
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
}
