@import '../../styles/colors.scss';
.rgpd-clauses {
  p {
    font-size: 15px;
  }
}
.rgpd__container {
  border: 1px solid $white;
  box-shadow: 0px 6px 6px -3px rgba(25, 29, 58, 0.02), 0px 10px 14px 1px rgba(25, 29, 58, 0.01),
    0px 4px 18px 3px rgba(25, 29, 58, 0.01);
  border-radius: 10px;
  padding: 0 25px;
  background: $white;

  @media screen {
  }
}
.subtitle {
  margin: 0;
  color: rgb(142, 142, 142);
}
