.race-item {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.choose-race__form {
  text-align: center;
}

.filter-races-block {
  width: 100%;
  text-align: center;
}
