@import '../../styles/colors.scss';
.segment-tab__wrapper {
  border: 1px solid red;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 20px;
  }
}

.additional-details__container {
  border: 2px solid $blue;
  margin: 15px 0 0 5px;
  padding: 5px 20px;
  display: flex;
  gap: 30px;
  justify-content: space-around;
  :nth-child(1) {
    width: 23%;
    text-align: center;
  }
  span {
    width: 17.5%;
  }
}

.segment-winner {
  text-transform: uppercase;
  font-size: 12px;
  color: $blue;
  width: 60px;
}

.segments-details-header {
  margin: 15px 0 0 5px;
  padding: 5px 20px;
  display: flex;
  gap: 22px;
  justify-content: space-around;
  font-weight: bold;
  text-transform: uppercase;
}

.points__text {
  font-size: 15px;
  color: $blue;
  cursor: pointer;
}

.raceOnCup__container {
  border: 2px solid #0c70e9;
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    margin: 25px 0;
  }

  p {
    width: 12.5%;
    text-align: center;
    &:first-child {
      width: 25%;
      text-align: left;
      padding-left: 12px;
    }
  }
}

.header-cupinfo {
  border: none;
  p {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 17px;
  }
}

.cup-points__container {
  display: flex;
  border: 1px solid $blue;
  margin-top: 20px;
  p {
    padding: 5px 10px;
    width: 40%;

    &:not(:first-child) {
      margin-left: 40px;
    }
  }
}

.cup-points__container-header {
  border: 1px solid transparent;
  p {
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
  }
}
