.buttons-group__wrapper {
  display: flex;
  gap: 40px;
  margin-bottom: 15px;
}

.join__btn {
  width: 10%;
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    border: none;
    color: none;
  }
}

.invite-block {
  text-align: right;
}

.invite-user__container {
  min-height: 100px;
  height: 380px;
}
