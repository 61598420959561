@import '../../styles/colors.scss';
.circle-item_btn-group {
  display: flex;
  justify-content: space-between;
}

.sub {
  background-color: $correctColor !important;
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
}

.unsub {
  background-color: $warning !important;
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
}
.circle-details {
  font-size: 12px;
}
.owner__text {
  font-weight: bold;
  color: green;
}
.btn-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
