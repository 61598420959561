@import './colors.scss';
@import './indents.scss';
.details-btn {
  padding: 5px 15px !important;
  border: none;
  outline: none;
  background: $main-blue;
  color: $white;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.3s all ease-in-out;
  &:hover {
    background: $blue;
  }
}

.text-center {
  text-align: center;
}

.big-width {
  padding: 150px 20px;
}

.coefficient__input {
  outline: none;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;
  width: 55px;
  font-size: 16px;
  &:focus {
    border: 2px solid $blue;
  }
}

.ic-antd {
  font-size: 22px;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
}
.delete {
  &:hover {
    color: red !important;
  }
}
.edit {
  color: #000 !important;
  &:hover {
    color: $blue !important;
  }
}

.table-element {
  display: flex;
  width: 100%;

  .table__item {
    width: 25%;
    padding: 10px;
    @media screen and (max-width: 480px) {
      padding: 0;

      &:nth-child(odd) {
        margin-left: 7px;
      }
      &:nth-child(even) {
        margin-right: 7px;
      }
    }

    @media screen and (max-width: 370px) {
      margin: 0;
    }
    &:not(:last-child) {
      border-right: 1px solid rgba(204, 204, 204, 0.5);
    }

    position: relative;
    color: #5f6c78;
    h3 {
      font-size: 12px;
      margin: 0;
      @media screen and (max-width: 480px) {
        // font-size: 11px;
        font-weight: bold;
      }
    }
    span {
      font-size: 21px;
      @media screen and (max-width: 520px) {
        font-size: 19px;
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
      @media screen and (max-width: 360px) {
        font-size: 14px;
      }
    }
  }
}

.map__container {
  width: 100%;
  height: 600px;
  position: relative;
  @media screen and (max-width: 500px) {
    height: 450px;
  }
  @media screen and (max-width: 450px) {
    height: 400px;
  }
}

.complete__container {
  display: flex;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.countdown__container {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;

  p {
    font-size: 25px;
    background: #ffe533;
    border-radius: 4px;
    width: 20%;
    text-align: center;
    @media screen and (max-width: 580px) {
      font-size: 20px;
    }
    @media screen and (max-width: 440px) {
      font-size: 19px;
    }
    @media screen and (max-width: 380px) {
      font-size: 15px;
      font-weight: bold;
    }
  }
}

.date__remaining {
  .days {
    font-size: 25px;
    font-weight: 500;
    color: #fee11f;
  }
  .hours {
    display: flex;
    gap: 6px;
    align-items: center;
    .time-IC {
      width: 35px;
      height: 35px;
    }
    p {
      font-size: 25px;
      font-weight: 500;
      color: #fee11f;
    }
  }
}
