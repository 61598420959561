.item__btn-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.card-content {
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-between;
}
