@import '../../styles/colors.scss';
.ant-card-head-title {
  text-transform: uppercase;
  color: $blue !important;
}
.single-race__date-title {
  font-size: 17px;
  margin-bottom: 15px;
  margin-top: 10px;
  color: $blue;
  font-weight: bold;
  text-align: center;
  @media screen and (max-width: 450px) {
    font-size: 14px;
  }
}
.race-information {
  display: flex;
  flex-direction: column;
  .wrapper {
    display: flex;
    justify-content: space-between;
  }
  .col-2 {
    width: 50%;
  }
  .racer__item {
    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
  }
  .display-team__text {
    text-transform: capitalize;
  }
  .item {
    display: flex;
    align-items: center;
    position: relative;
    gap: 10px;
    font-size: 15px;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
    &__text {
      margin-left: 30px;
    }
    &__svg {
      position: absolute;
      top: 3px;
    }
  }
}

.preview-race__img-container {
  width: 320px;
  height: 200px;
  margin: 0 auto;
  margin-bottom: 10px;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
}
.preview-race__img {
  width: 100%;
  height: 100%;
}

.race-header__info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;
  p {
    margin-bottom: 0;
  }
  .col-1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .names {
      display: flex;
      flex-direction: column;
      width: calc(100% - 80px);

      .race-name {
        width: calc(100% - 10px);
        overflow: hidden;
      }
      .circle-name {
        color: $black;
      }
    }
    .details-btn {
      padding: 5px 10px !important;
      font-size: 12px;
    }
  }
  .col-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: $warning;
    }
  }
}

.locale-date__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
