@import '../../../styles/colors.scss';

.edit-element__form {
  width: 55%;
  margin: 0 auto;
  padding: 5px 15px;
  input {
    margin-bottom: 15px;
  }
  &-buttons {
    display: flex;
    margin-top: 15px;
    text-align: right;
    justify-content: flex-end;
    gap: 10px;
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
    button {
      width: 120px;
      @media screen and (max-width: 650px) {
        width: 100%;
      }
    }
  }
}
.edit-text__title {
  color: $blue;
  margin-left: 10px;
  @media screen and (max-width: 600px) {
    font-size: 25px;
  }
  @media screen and (max-width: 440px) {
    font-size: 22px;
  }
}
