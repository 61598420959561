@import '../../styles/colors.scss';
.globe-ic {
  &:hover {
    fill: $blue;
  }
}

.active {
  color: $blue;
}
