@import '../../../styles/colors.scss';
.cupDetails__container {
  border: 1px solid $white;
  box-shadow: 0px 6px 6px -3px rgba(25, 29, 58, 0.02), 0px 10px 14px 1px rgba(25, 29, 58, 0.01),
    0px 4px 18px 3px rgba(25, 29, 58, 0.01);
  border-radius: 4px;
  background: $white;
  margin-bottom: 15px;
  padding: 0 15px;
  position: relative;

  .date-text {
    text-align: left;
    font-size: 25px;
  }

  // & > * {
  //   padding: 0 15px;
  // }
  p {
    margin: 0;
  }
  .title {
    text-align: left;
  }
}

.calculate-button {
  background-color: #3eb8e0;
}

.popup__marker {
  width: 250px;

  .popup__title {
    text-align: center;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: bold;
  }
  .popup__subinfo {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.mapboxgl-popup-close-button {
  right: 8px;
  font-size: 20px;
  outline: none;
}

.object-details__buttons {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  @media screen and (max-width: 385px) {
    flex-direction: column;
  }
}
