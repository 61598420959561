.update-email__form {
  width: 100%;
  margin: 0 auto;
  padding: 5px 25px;
  margin-top: 5%;
  .title {
    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
}

.update-email__button {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}
