.layout {
  background-color: #f1f1f9;
}
.container {
  width: 100%;
  padding: 10px 20px;
  height: 100%;
  min-height: 100vh;
  @media screen and (max-width: 420px) {
    padding: 10px 5px;
  }
}
