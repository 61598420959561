.cup-date__wrapper {
  display: flex;
  gap: 20px;
  margin-left: 25px;
  margin-top: -10px;
  // h3 {
  //   margin: 0;
  //   padding: 0;
  // }
}

.cup-row__wrapper {
  justify-content: space-between;
  flex-wrap: wrap;
}
.all-cups__container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  @media screen and (max-width: 820px) {
    justify-content: center;
  }
}

.cup-date-row {
  display: flex;
  gap: 45px;
}
.cup-date-item {
  text-align: left;
  color: #0c70e9;
}

.first-row {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
