@import '../../styles/colors.scss';
.race-action__form {
  margin-bottom: 20px;
  margin-top: 15px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  @media screen and (max-width: 1020px) {
    flex-direction: column;
  }
}
.btn {
  padding: 6px 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: $white !important;
  border: none;
  cursor: pointer;
}
.race-register__btn {
  background: $correctColor;
}
.race-unregistern__btn {
  background: $secondary;
}

.race-complete__btn {
  background: $warning;
  margin-left: 30px;
  &:disabled {
    background: #ccc;
  }
}

.segment-tab__label-wrapper {
  position: relative;
  width: 70%;
  span {
    position: absolute;
    &:nth-child(1) {
    }
    &:nth-child(2) {
    }
    &:nth-child(3) {
    }
    &:nth-child(4) {
    }
    &:nth-child(5) {
    }
  }
}

.no-users__result {
  font-size: 23px;
  text-align: center;
  margin-top: 45px;
}

.date-text {
  font-size: 19px;
  text-align: center;
  margin-bottom: 10px;
}

.race-oncircle__info {
  width: 100%;
  min-height: 90px;
  border: 1px solid $blue;
  border-radius: 4px;
  @media screen and (max-width: 480px) {
    min-height: 70px;
  }
}

.count-days {
  font-size: 25px;
  color: $warning;
  text-align: center;
  margin-bottom: 30px;
}

.details-race-general-info {
  display: flex;
  width: 100%;

  .details-race {
    width: 25%;
    padding: 10px;
    &:not(:last-child) {
      border-right: 1px solid rgba(204, 204, 204, 0.5);
    }
    position: relative;
    color: #5f6c78;
    h3 {
      font-size: 12px;
      margin: 0;
    }
    span {
      font-size: 21px;
    }
  }
}

.race-details-img-preview {
  text-align: center;
}

.spinner-title {
  font-size: 22px;
  text-align: center;
  margin-top: 20%;
  background: #f1f1f9 !important;
}

.strava-acitvity_label {
  font-weight: bold;
}

.action__buttons {
  display: flex;
  gap: 15px;
}

.complete__container {
  display: flex;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}
