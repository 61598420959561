.aboutUs__text {
  p {
    b {
      font-size: 23px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
      @media screen and (max-width: 850px) {
        font-size: 17px;
      }
      @media screen and (max-width: 450px) {
        font-size: 15px;
      }
    }
  }
  .aboutUs__answer {
    font-size: 20px;
    @media screen and (max-width: 1024px) {
      font-size: 17px;
    }
    @media screen and (max-width: 850px) {
      font-size: 14px;
    }
    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
}

.connect-strava__wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: 0;
}

.connect-strava__btn {
  width: 290px;
  background: url('../../assets/strava_connect.png') 0 0 no-repeat;
  height: 48px;
  border: none;
  outline: none;
  margin: 0 auto;
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  cursor: pointer;
}
