.search-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
}

.input-section {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.input-field {
  width: 40% !important;
}
.date-field {
  width: 20%;
}

.edit-btn {
  border: none;
  background: none;
  cursor: pointer;
  text-transform: uppercase;
  color: #000;
  text-align: center;
}
