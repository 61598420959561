@import '../../styles/colors.scss';

.circle-form {
  width: 450px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.circle-table__actions {
  display: flex;
  gap: 15px;
  .ic-antd {
    font-size: 22px;
    transition: color 0.3s ease-in-out;
  }
  .delete {
    &:hover {
      color: red;
    }
  }
  .edit {
    &:hover {
      color: $blue;
    }
  }
}

.circle-items__container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
}
