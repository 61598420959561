.edit-segment__form {
  width: 70%;
  margin: 0 auto;
  padding: 5px 15px;
}
.edit-element__form {
  &-datepicker {
    display: flex;
    gap: 10px;
  }
}

.race-form {
  width: 67%;
  padding: 5px 15px;
}

.edit-race__form {
  display: flex;
  justify-content: flex-end;
  padding: 0 43px 0 0;
  gap: 10px;
}
.edit__race-btn {
  width: 120px;
  text-align: right;
}
