.cup-name {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

.cupItem {
  width: 45%;

  @media screen and (max-width: 820px) {
    width: 70%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
