@import '../../styles/colors.scss';
.detail-circle {
  border: 1px solid $white;
  box-shadow: 0px 6px 6px -3px rgba(25, 29, 58, 0.02), 0px 10px 14px 1px rgba(25, 29, 58, 0.01),
    0px 4px 18px 3px rgba(25, 29, 58, 0.01);
  border-radius: 4px;
  background: $white;
  // width: 950px;
  // margin: 0 auto;
}
.circle-item_btn-group {
  padding: 15px;
  padding-bottom: 20px;
}
.circle-description {
  font-size: 22px;
}
.member-wrapper {
  width: 60%;
}
.member-item {
  border: 1px solid $blue;
  border-radius: 4px;
  margin-bottom: 10px;
  span {
    font-size: 19px;
  }
}

.circle__description {
  font-size: 22px;
  padding-left: 20px;
}

.map__container {
  width: 100%;
  height: 500px;
}
