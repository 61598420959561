* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
}

.title {
  text-transform: uppercase;
  text-align: center;
}
.notification-border {
  border-radius: 16px !important;
}

.success {
  background-color: #90ee90 !important;
}

.error {
  background-color: #ffcccb !important;
}

.published {
}
.not-published {
}
