@import '../../styles/colors.scss';
.activity-item-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.activity-item-container {
  height: 500px;
  position: relative;
}
.activity-items {
  max-height: 420px;
  min-height: 420px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue;
    border-radius: 100px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ccc;
    padding: 5px;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 10px;
    span {
      &:nth-child(1) {
        width: 40%;
      }
      &:nth-child(2) {
        width: 10%;
      }
    }
    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      &:hover {
        color: $blue;
      }
    }
  }
}

.result-container {
  border-radius: 30px;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  bottom: -105px;
  z-index: 9999;
  height: 70px;
  border: 1px solid $blue;
  background-color: #fff;
  margin-top: auto;
  span {
    font-size: 19px;
  }
  button {
    padding: 5px 15px;
    border: 1px solid $blue;
    background-color: $blue;
    color: $white;
    cursor: pointer;
    border-radius: 7px;
  }
}
